import styled from 'styled-components';
import { useState, useEffect, useRef, useCallback } from 'react';
// components
import Notification from 'components/molecules/common/Notification';
import EmptyContent from 'components/molecules/common/EmptyContent';
import SpinLoading from 'components/atoms/common/SpinLoading';
import VerticalScrollLayout from 'cds/layout/VerticalScroll';
import Icon from 'cds/icons';
// slices
import { getNotificationList, readAllNotification, removeAllNotification } from 'slices/user.thunk';
// hooks
import { useSelector, useDispatch } from 'hooks/common/useStore';
import useInfiniteScroll from 'hooks/common/useInfiniteScroll';
import useAccordingToScroll from 'hooks/common/useAccordingToScroll';
// styles
import * as animations from 'lib/styles/animations';
import { zIndex, palette, mediaQuery } from 'cds/styles';

/**
 * 알림 센터
 * @param {object} props
 * @param {object} props.isVisible 표시 여부
 * @param {Function} props.onClose 알림 닫기
 */
const NotificationCenter = ({ isVisible, onClose }) => {
  const dispatch = useDispatch();
  const notificationList = useSelector(state => state.user.notificationList);
  const notificationInfo = useSelector(state => state.user.notificationInfo);
  const existNotification = useSelector(state => state.user.newsCount.news_unread_count);

  const [isLoading, setIsLoading] = useState(true);
  const enableMoreData = useRef(true);
  const target = useRef(null);

  const [stickyRef, isSticky] = useAccordingToScroll({ start: 84 });

  useInfiniteScroll({
    target,
    onIntersect: ([entry]) => {
      if (entry.isIntersecting) {
        onLoadData();
      }
    },
  });

  /** 알림 전체 읽기  */
  const onReadAll = () => {
    if (existNotification === 0) {
      return;
    }
    dispatch(readAllNotification());
  };

  /** 알림 전체 제거 */
  const onRemoveall = () => {
    if (notificationList.length === 0) {
      return;
    }
    dispatch(removeAllNotification());
  };

  /** 알림 데이터 호출 함수 */
  const onLoadData = useCallback(async () => {
    if (!enableMoreData.current) {
      return;
    }

    if (notificationInfo.nextPage > notificationInfo.total_page) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    enableMoreData.current = false;
    const res = await dispatch(getNotificationList({ page: notificationInfo.nextPage })).catch(
      () => {},
    );

    setIsLoading(false);
    if (res.payload && res.payload.length) {
      enableMoreData.current = true;
    }
  }, [dispatch, notificationInfo]);

  /** 알림센터 클릭 끄기 이벤트 작업 */
  /* eslint-disable */
  useEffect(() => {
    if (!isVisible) {
      return;
    }

    const onClickScreen = e => {
      const path = e.path || (e.composedPath && e.composedPath());
      if (!path) {
        return;
      }

      if (!path.includes(stickyRef.current)) {
        onClose();
      }
    };

    document.addEventListener('click', onClickScreen);
    return () => document.removeEventListener('click', onClickScreen);
  }, [isVisible]);
  /* eslint-enable */

  const NotiList = notificationList.map((noti, idx) => (
    <li key={`noti._id-${idx}`}>
      <Notification noti={noti} />
    </li>
  ));

  return (
    <NotificationLayout isVisible={isVisible}>
      <Layout isVisible={isVisible} isSticky={isSticky} ref={stickyRef}>
        <Header>
          <Title>알림</Title>
          <div>
            <ActiveButton active={existNotification > 0} onClick={onReadAll}>
              전체읽기
            </ActiveButton>
            <ActiveButton active={NotiList.length > 0} onClick={onRemoveall}>
              전체삭제
            </ActiveButton>
            <ActiveButton onClick={onClose}>
              <Icon name="ic_close_m" width="18" height="18" fill={palette.grey50} />
            </ActiveButton>
          </div>
        </Header>

        <Body>
          <BodyContent>
            {NotiList}
            <div ref={target} />
          </BodyContent>

          {!NotiList.length && !isLoading && (
            <EmptyLayout>
              <EmptyContent
                icon="ic_alarm_s"
                w={52}
                h={52}
                title="알림이 없어요"
                body="새로운 소식이 들어오면 알려드릴게요"
              />
            </EmptyLayout>
          )}

          {isLoading && (
            <LoadingLayout>
              <SpinLoading />
            </LoadingLayout>
          )}
        </Body>
      </Layout>
    </NotificationLayout>
  );
};

const NotificationLayout = styled.div`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  margin: auto;
  z-index: ${zIndex.notification};

  ${mediaQuery.small} {
    bottom: auto;
    width: 1368px;
    max-width: calc(100% - 32px);
  }

  ${mediaQuery.large} {
    bottom: auto;
    width: 100%;
  }
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100%;
  height: 90vh;
  min-height: 90vh;
  background-color: ${palette.system.BNB};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  animation: 0.2s ${animations.notificationOpen} ease;
  transform-origin: top;
  overflow: hidden;

  ${mediaQuery.small} {
    top: 68px;
    right: 0;
    left: auto;
    max-width: 360px;
    margin-top: 4px;
    border-radius: 12px;
    min-height: 440px;
  }

  ${mediaQuery.large} {
    max-height: calc(600px);
    right: 0;
    margin-left: auto;
    z-index: ${zIndex.notification};
    transition: 0.2s ease;
    transform: ${({ isSticky }) => (isSticky ? 'translateY(-64px)' : 'translateY(0)')};
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 4px 8px 12px;
  border-bottom: 1px solid ${palette.BTN.BG};

  & > div {
    display: flex;
    align-items: center;
  }
`;

const Title = styled.h1`
  font-size: 18px;
  font-weight: 500;
  color: ${palette.grey75};
`;

const ActiveButton = styled.button`
  font-size: 12px;
  color: ${({ active }) => (active ? palette.grey50 : palette.grey0)};
  transition: 0.1s ease;

  & > svg {
    display: list-item;
    margin: 8px;
  }

  & ~ & {
    margin-left: 12px;
  }
`;

const Body = styled.ul`
  flex: 1;
  position: relative;
  overflow-y: auto;

  & > li {
    white-space: normal;
  }
`;

const BodyContent = styled(VerticalScrollLayout)`
  min-height: 100%;
`;

const LoadingLayout = styled.div`
  margin-top: -64px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0 24px 0;
`;

const EmptyLayout = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto auto 60px;
`;

export default NotificationCenter;
